// src/store/store.ts
import { configureStore, combineReducers, AnyAction } from "@reduxjs/toolkit";
import { createWrapper, HYDRATE } from "next-redux-wrapper";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // LocalStorage for web
// import { createNoopStorage } from "redux-persist/lib/storage/noop"; // Noop storage for SSR

// Noop storage for SSR
const createNoopStorage = () => ({
  getItem(_key: any) {
    return Promise.resolve(null);
  },
  setItem(_key: any, value: any) {
    return Promise.resolve(value);
  },
  removeItem(_key: any) {
    return Promise.resolve();
  },
});

// Import slices
import branchesReducer from "./slices/table_booking_flow/branchesSlice";
import slotsSlice from "./slices/table_booking_flow/slotsSlice";
import menuBuffetPriceReducer from "./slices/table_booking_flow/menuBuffetPriceSlice";
import promotionsVouchersSlice from "./slices/promotionsAndVoucher";
import signPopupSlice from "./slices/auth/signPopupSlice";
import smileSlice from "./slices/table_booking_flow/smileSlice";
import happinessCartSlice from "./slices/happiness-card/happinessCartSlice";
import deliveryCatalogSlice from "./slices/ubqDelivery/deliveryCatalogSlice";
import UBQCartSlice from "./slices/ubqDelivery/UBQCartSlice";
import deliveryAddressSlice from "./slices/ubqDelivery/deliveryAddressSlice";
import branchInfoSlice from "./slices/branchInfoSlice/branchInfoSlice";
import utmSourceSlice from "./slices/utm/utmSlice";
import visitorInfoSlice from "./slices/visitorInfo/visitorInfoSlice";
import restaurantsSlice from "./slices/restaurantsSlice";
import UserSlice from "./slices/users/user.slice"
import metaDataSlice from "./slices/pagesMetaData/metaDataSlice"
import pageDataReducer from "./slices/pageDataSlice"
import deliveryBranchesReducer from "./slices/deliveryBranchesSlice"


// Combine reducers
const rootReducer = combineReducers({
  utm: utmSourceSlice,
  branchInfoSlice: branchInfoSlice,
  branches: branchesReducer,
  slots: slotsSlice,
  menuBuffetPrice: menuBuffetPriceReducer,
  smiles: smileSlice,
  authPopup: signPopupSlice,
  promotionsVouchers: promotionsVouchersSlice,
  happinessCart: happinessCartSlice,
  deliveryCatalog: deliveryCatalogSlice,
  UBQCartSlice: UBQCartSlice,
  deliveryAddressSlice: deliveryAddressSlice,
  visitorInfo: visitorInfoSlice,
  restaurants: restaurantsSlice,
  user: UserSlice,
  metaDataSlice: metaDataSlice,
  pageData: pageDataReducer,
  deliveryBranches: deliveryBranchesReducer,

});

// Handle HYDRATE action
const reducer = (state: ReturnType<typeof rootReducer> | undefined, action: AnyAction) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // Use previous state
      ...action.payload, // Apply delta from hydration
    };

    // Ensure client-side state is preserved and not overwritten by the server during hydration
    if (state?.restaurants) nextState.restaurants = state.restaurants;
    if (state?.utm) nextState.utm = state.utm;

    return nextState;
  }
  return rootReducer(state, action);
};

// Redux-Persist configuration
const persistConfig = {
  key: "root",
  storage: typeof window !== "undefined" ? storage : createNoopStorage(), // Noop storage for SSR
  whitelist: ["utm", "user", "visitorInfo", "branches","deliveryBranches"], // Only persist essential slices
  // throttle: 1000, // Optional: Throttle persisting to reduce writes
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, reducer);

// Create a function to configure the store
export const makeStore = () =>
  configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",

  });

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];

// Create wrapper with SSR compatibility using next-redux-wrapper
export const wrapper = createWrapper<AppStore>(makeStore);