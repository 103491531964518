import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Required CSS

const PageSkeleton = () => {
    return (
        <div className='md:w-[calc(100%-276px)] p-5 container'>
            {/* Header Section */}
            <div className="flex justify-between items-center mb-6">
                {/* Logo */}
                <Skeleton circle width={50} height={50} />
                {/* Restaurants */}
                <Skeleton width={100} height={30} />
                {/* Location Select */}
                <Skeleton width={150} height={30} />
            </div>

            {/* Title */}
            <div className="text-center mb-6">
                <Skeleton width={250} height={30} />
                <Skeleton width={350} height={25} style={{ marginTop: 8 }} />
            </div>

            {/* Carousel Section */}
            <div className="mb-5">
                <Skeleton height={440} />
            </div>

            <Skeleton height={110} style={{ marginTop: 8 }} />

            {/* Location and Reserve Table Section */}
            <div className="flex justify-between items-center">
                <Skeleton width={200} height={40} />
                <Skeleton width={150} height={50} />
            </div>
            <Skeleton height={110} style={{ marginTop: 50 }} />
            <Skeleton height={110} style={{ marginTop: 8 }} />
            <Skeleton height={110} style={{ marginTop: 8 }} />
            <Skeleton height={110} style={{ marginTop: 8 }} />
        </div>
    );
};

export default PageSkeleton;
