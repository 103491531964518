import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

/**
 * Type representing the response structure for loyalty points (smile points).
 */
type FetchSmileResponse = {
  loyalty_points: number; // Number of loyalty points
};

/**
 * Type representing the state for smile points.
 */
interface SmileState {
  data: FetchSmileResponse | null; // Data containing loyalty points
  error: string | null; // Error message in case of failure
  loading: boolean; // Loading state for the request
}

// Initial state for smile (loyalty points)
const initialState: SmileState = {
  data: null,
  error: null,
  loading: false,
};

/**
 * Async thunk to fetch smile points (loyalty points).
 * This fetches data from an API endpoint.
 * 
 * @returns {Promise<FetchSmileResponse>} The fetched smile (loyalty points) data or an error.
 */
export const fetchSmile = createAsyncThunk(
  'smile/fetchSmile',
  async (_, { rejectWithValue }) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const response = await fetch("/api/v1/loyalty-points-count", {
        method: "GET",
        headers: myHeaders,
        redirect: "follow" as RequestRedirect,
      });

      // Check if the response is not successful
      if (!response.ok) {
        const errorData = await response.json();
        if(errorData?.error.includes('Blocked by Cloudflare'))
          toast.warn(`${errorData?.error}`)
        return rejectWithValue(errorData);
      }

      // Parse the response data
      const result = await response.json();
      return result?.results; // Return the fetched smile data
    } catch (error: any) {
      // Return the error message if the request fails
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Slice for managing smile (loyalty points) state.
 * It includes the reducer and extra reducers for handling async thunk actions.
 */
const smileSlice = createSlice({
  name: 'smile',
  initialState,
  reducers: {
    // Additional reducers can be added here if needed
  },
  extraReducers: (builder) => {
    builder
      /**
       * Handles the pending state when fetching smile points.
       * 
       * @param {SmileState} state - The current state of the slice.
       */
      .addCase(fetchSmile.pending, (state) => {
        state.loading = true; // Set loading to true while fetching
        state.error = null; // Clear any previous errors
      })

      /**
       * Handles the fulfilled state when smile points are successfully fetched.
       * 
       * @param {SmileState} state - The current state of the slice.
       * @param {PayloadAction<FetchSmileResponse>} action - The action containing the fetched smile data.
       */
      .addCase(fetchSmile.fulfilled, (state, action: PayloadAction<FetchSmileResponse>) => {
        state.loading = false; // Set loading to false after fetching
        state.data = action.payload; // Set the fetched smile data
      })

      /**
       * Handles the rejected state when fetching smile points fails.
       * 
       * @param {SmileState} state - The current state of the slice.
       * @param {PayloadAction<string>} action - The action containing the error message.
       */
      .addCase(fetchSmile.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false; // Set loading to false after failure
        state.error = action.payload; // Set the error message
      });
  },
});

// Export the reducer to be used in the store
export default smileSlice.reducer;
