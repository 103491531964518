// Extend the Window interface to include dataLayer
declare global {
    interface Window {
        dataLayer: Record<string, any>[];
    }
}

import { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';

/**
 * Helper function to set a cookie that expires in 7 days.
 * 
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value to store in the cookie.
 */
const setCookie = (name: string, value: string) => {
    const date = new Date();
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000); // Set expiration to 7 days from now
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
};

/**
 * Helper function to check if any UTM keys exist in the query string.
 * 
 * @param {URLSearchParams} searchParams - The URL search parameters.
 * @param {string[]} utmKeys - The UTM keys to check for.
 * @returns {boolean} - Returns true if any UTM key exists, otherwise false.
 */
const hasUTMParams = (searchParams: URLSearchParams, utmKeys: string[]): boolean => {
    return utmKeys.some(key => searchParams.has(key));
};

/**
 * Custom hook that captures UTM parameters from the URL, saves them in cookies for 7 days,
 * and pushes the UTM data to Google Tag Manager's dataLayer.
 *
 * @example
 * // Usage in a React component:
 * useTrackUTM();
 *
 * @returns {void}
 */
const useTrackUTM = (): void => {
    const searchParams = useSearchParams();

    useEffect(() => {
        const utmParams: { [key: string]: string } = {};
        const utmKeys = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];

        // Check if UTM keys exist in the query string before proceeding
        if (!hasUTMParams(searchParams, utmKeys)) {
            return; // Exit if no UTM keys are found
        }

        // Extract UTM parameters from the URL search params
        utmKeys.forEach((key) => {
            const value = searchParams.get(key);
            if (value) {
                utmParams[key] = value;
                // Save UTM parameters to cookies for 7 days
                // setCookie(key, value);
            }
        });

        // Check if there are any UTM params to save
        if (Object.keys(utmParams).length > 0) {
            // Save all UTM parameters as a campaign cookie
            setCookie('__campaign', JSON.stringify(utmParams));

            // Push UTM parameters to Google Tag Manager (GTM) dataLayer
            if (typeof window !== 'undefined' && window.dataLayer) {
                window.dataLayer.push({
                    event: "utm_parameters",
                    ...utmParams,
                });
            }
        }
    }, [searchParams]);
};

export default useTrackUTM;