'use client'; // Only for client-side components
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { makeStore } from '@/store/store';
import PageSkeleton from '@/components/skeletons/PageSkeleton';


// Memoize the store to prevent it from being recreated on every render
const store = makeStore();
const persistor = persistStore(store);

const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <Provider store={store}>
      {/* Provide a lightweight loading component or skeleton */}
      <PersistGate loading={<PageSkeleton />} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}

export default StoreProvider;


// 'use client'; // Only for client-side components

// import React, { useEffect, useState } from 'react';
// import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
// import { persistStore } from 'redux-persist';
// import { makeStore } from '@/store/store';

// const store = makeStore();
// const persistor = persistStore(store);

// const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  
//   const [isClient, setIsClient] = useState(false);

//     // Ensure this component only runs persist on the client
//     useEffect(() => {
//       setIsClient(true);
//     }, []);
  

//  return <Provider store={store}>
//     {isClient ? (
//         <PersistGate loading={null} persistor={persistor}>
//           {children}
//         </PersistGate>
//       ) : (
//         children
//       )}
//   </Provider>
// }

// export default StoreProvider;

