import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { BuffetData } from '@/types/next'; // Adjust the import path according to your project structure
import { convertTo24HourFormat } from '@/lib/utils';
import { toast } from 'react-toastify';

/**
 * @interface MenuBuffetPriceState
 * Represents the state structure for menu buffet prices.
 */
interface MenuBuffetPriceState {
    data: BuffetData[];    /** Array of buffet data */
    loading: boolean;      /** Loading state to track if data is being fetched */
    error: string | null;  /** Holds error messages, if any */
}

/** 
 * Initial state for the menu buffet price slice 
 * @const
 * @type {MenuBuffetPriceState} 
 */
const initialState: MenuBuffetPriceState = {
    data: [],              /** Start with an empty array for buffet data */
    loading: false,        /** Initially set to not loading */
    error: null            /** No error initially */
};

/**
 * Async thunk for fetching menu buffet prices based on branch ID, reservation date, slot ID, and reservation time.
 * 
 * @param {Object} params - The parameters for the API call.
 * @param {number} params.branch_id - The branch ID to fetch buffet prices for.
 * @param {string} params.reservation_date - The reservation date for which to fetch prices.
 * @param {number} params.slot_id - The slot ID for which to fetch buffet data.
 * @param {string} params.reservation_time - The reservation time (12-hour format) to convert and send in the request.
 * @returns {Promise<BuffetData[]>} - A promise resolving to the buffet data or rejecting with an error message.
 */
export const fetchMenuBuffetPrices = createAsyncThunk(
    'menuBuffetPrice/fetchMenuBuffetPrices',
    async ({ branch_id, reservation_date, slot_id, reservation_time }: { branch_id: number, reservation_date: string, slot_id: number, reservation_time: string }, { rejectWithValue }) => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                branch_id: branch_id.toString(),
                reservation_date,
                slot_id,
                reservation_time: convertTo24HourFormat(reservation_time),  /** Convert reservation time to 24-hour format */
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow" as RequestRedirect,
            };

            const response = await fetch("/api/v1/menu-buffet-price", requestOptions);

            /** If response is not OK, return the error message */
            if (!response.ok) {
                const errorData = await response.json();
                if (errorData?.error.includes('Blocked by Cloudflare'))
                    toast.warn(`${errorData?.error}`)
                return rejectWithValue(errorData);
            }

            /** Parse and return the buffet data */
            const result = await response.json();
            return result?.results?.buffets.buffet_data;

        } catch (error: any) {
            /** Handle any errors that occur during the request */
            return rejectWithValue(error.message);
        }
    }
);

/**
 * Slice for managing menu buffet prices.
 * Includes loading, error, and data handling for fetching buffet prices.
 */
const menuBuffetPriceSlice = createSlice({
    name: 'menuBuffetPrice',
    initialState,
    reducers: {},  /** Reducers can be added here if needed in the future */

    /** 
     * Extra reducers to handle async actions (fetching data).
     * Handles the state for loading, success, and error when fetching menu buffet prices.
     */
    extraReducers: (builder) => {
        builder
            /**
             * When the request is pending, set the loading state to true and clear any previous errors.
             */
            .addCase(fetchMenuBuffetPrices.pending, (state) => {
                state.loading = true;
                state.error = null;
            })

            /**
             * When the request is successful, store the retrieved buffet data and stop loading.
             * 
             * @param {PayloadAction<BuffetData[]>} action - The action payload containing the fetched buffet data.
             */
            .addCase(fetchMenuBuffetPrices.fulfilled, (state, action: PayloadAction<BuffetData[]>) => {
                state.loading = false;
                state.data = action.payload;
            })

            /**
             * When the request fails, stop loading and store the error message.
             * 
             * @param {PayloadAction<any>} action - The action payload containing the error message.
             */
            .addCase(fetchMenuBuffetPrices.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

/** 
 * Export the reducer to be used in the store.
 * @exports menuBuffetPriceSlice.reducer
 */
export default menuBuffetPriceSlice.reducer;
