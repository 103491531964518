import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {  UserProfile } from "@/types/next";
import { toast } from "react-toastify";

/**
 * The shape of the user slice's state.
 * @typedef {Object} UserState
 * @property {UserProfile | null} user - The user's profile data or null if not available.
 * @property {boolean} loading - A boolean representing whether the data is being loaded.
 * @property {string | null} error - A string representing any error message or null if no error.
 */
interface UserState {
  user: UserProfile | null;
  loading: boolean;
  error: string | null;
}

/**
 * The initial state for the user slice.
 * @type {UserState}
 */
const initialState: UserState = {
  user: null,
  error: null,
  loading: false,
};

/**
 * Async thunk to fetch user details.
 * @function fetchUserDetails
 * @async
 * @param {Object} params - Parameters for the thunk.
 * @param {boolean} params.session - Boolean indicating if the user is logged in.
 * @returns {Promise<UserProfile | false>} The user profile data if the session is valid, otherwise false.
 * @throws Will throw an error if the API call fails.
 */
export const fetchUserDetails = createAsyncThunk(
  "userDetails/fetchUserDetails",
  async ({ session }: { session: boolean }, { rejectWithValue }) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let url = "";
      if (session) {
        url = "/api/v1/user"; // API endpoint for logged-in users
      } else {
        return false; // Return false if no session
      }

      const response = await fetch(url, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow" as RequestRedirect,
      });

      if (!response.ok) {
        const errorData = await response.json();
        if(errorData?.error.includes('Blocked by Cloudflare'))
          toast.warn(`${errorData?.error}`)
        return rejectWithValue(errorData); // Reject if the response is not OK
      }

      const result = await response.json();
      return result?.results as UserProfile; // Return the user profile data
    } catch (error: any) {
      return rejectWithValue(error.message); // Handle errors
    }
  }
);

/**
 * Slice to manage user details.
 */
const UserSlice = createSlice({
  name: "userDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /**
       * Handles the pending state of fetchUserDetails thunk.
       * @param {UserState} state - The current state.
       */
      .addCase(fetchUserDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      /**
       * Handles the fulfilled state of fetchUserDetails thunk.
       * @param {UserState} state - The current state.
       * @param {PayloadAction<UserProfile | false>} action - The action containing user data or false.
       */
      .addCase(
        fetchUserDetails.fulfilled,
        (state, action: PayloadAction<UserProfile | false>) => {
          state.loading = false;
          if (action.payload) {
            state.user = action.payload as UserProfile; // Set user data if available
          } else {
            state.user = null; // No user data (false case)
          }
        }
      )

      /**
       * Handles the rejected state of fetchUserDetails thunk.
       * @param {UserState} state - The current state.
       * @param {PayloadAction<any>} action - The action containing the error message.
       */
      .addCase(
        fetchUserDetails.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default UserSlice.reducer;
