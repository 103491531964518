// /src/store/slice/pagesMetaData/metaDataSlice.ts

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { toast } from "react-toastify";

export interface MetaData {
    page_code: string;
    page_title: string;
    page_description: string | null;
    page_meta_title: string;
    page_meta_description: string;
    page_keywords: string;
    page_breadcrumb_url: string;
    page_alias: string;
    Active: string; // assuming "Active" is a string, although it could also be a boolean if "1" and "0" map to true/false
  }

  
// Async thunk for fetching metadata

export const fetchMetaData = createAsyncThunk(
  'metadata/fetchMetaData',
  async (_, { rejectWithValue }) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const response = await fetch(`${process.env.NEXT_PUBLIC_BASEAPI}/api/v1/page/default`, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow" as RequestRedirect,
      });

      if (!response.ok) {
        const errorData = await response.json();
        if(errorData?.error.includes('Blocked by Cloudflare'))
          toast.warn(`${errorData?.error}`)
        return rejectWithValue(errorData);
      }
      const result = await response.json();
      return result as  MetaData[]; // Assuming the result is structured this way
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

interface MetaDataState {
  data: MetaData[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: MetaDataState = {
  data: null,
  loading: false,
  error: null,
};

const metaDataSlice = createSlice({
  name: "metaData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMetaData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMetaData.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchMetaData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Failed to fetch metadata";
    });
    // Handle server-side hydration
    builder.addCase(HYDRATE, (state, action: any) => {
      if (action.payload.metaData?.data) {
        state.data = action.payload.metaData.data;
      }
    });
  },
});

export default metaDataSlice.reducer;
