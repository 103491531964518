// src/components/BodyClassSetter.tsx
"use client"; // Make this component a Client Component

import { usePathname } from "next/navigation";
import { generateSlugClass } from "@/lib/utils";
import { useEffect } from "react";

export function BodyClassSetter() {
  const pathname = usePathname();
  const bodyClass = generateSlugClass(pathname);

  useEffect(() => {
    document.body.className = bodyClass; // Set the body class
  }, [bodyClass]);

  return null; // This component doesn't render anything
}
