import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { branchListing_Response } from "@/types/next";
import { HYDRATE } from "next-redux-wrapper";
import { toast } from "react-toastify";

// Async thunk for fetching restaurants

export const  fetchRestaurantsData =  createAsyncThunk(
    'branch/fetchBranch',
    async (_, { rejectWithValue }) => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const response = await fetch(`${process.env.NEXT_PUBLIC_BASEAPI}/api/v2/search/branches?size=300&page=0`, {
                method: "GET",
                headers: myHeaders,
                redirect: "follow" as RequestRedirect,
            });
            
            if (!response.ok) {
                const errorData = await response.json();

                if(errorData?.error.includes('Blocked by Cloudflare'))
                  toast.warn(`${errorData?.error}`)
                return rejectWithValue(errorData);
            }
            const result = await response.json();
            return result as branchListing_Response; // Assuming the result is structured this way
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

interface BranchesState {
  restaurants: branchListing_Response | null;
  loading: boolean;
  error: string | null;
}

const initialState: BranchesState = {
  restaurants: null,
  loading: false,
  error: null,
};

const restaurantsSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRestaurantsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchRestaurantsData.fulfilled, (state, action) => {
      state.restaurants = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchRestaurantsData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Failed to fetch restaurants";
    });
    // Handle server-side hydration
    builder.addCase(HYDRATE, (state, action: any) => {
      if (action.payload.branches.restaurants) {
        state.restaurants = action.payload.branches.restaurants;
      }
    });
  },
});

export default restaurantsSlice.reducer;