"use client";
import { signOut } from "next-auth/react";
import { useEffect } from "react";
import { getAuth, signOut as signoutfb } from "firebase/auth";
import { getFirestore, terminate } from "firebase/firestore";
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const clearFirebaseDatabases = async () => {
  try {
    // Shut down Firebase Auth
    const auth = getAuth();
    signoutfb(auth);

    // Shut down Firestore
    const firestore = getFirestore();
    await terminate(firestore);

    console.log("Firebase services shut down.");
  } catch (error) {
    console.error("Error shutting down Firebase services:", error);
  }
};

const AppStorageHandler = ({ children }: { children: React.ReactNode }) => {
  const clearAllData = async () => {
    try {
      // Clear localStorage
      localStorage.clear();
      console.log("LocalStorage cleared.");

      // Clear sessionStorage
      sessionStorage.clear();
      console.log("SessionStorage cleared.");

      // Clear IndexedDB
      if (window.indexedDB) {
        const databases = await indexedDB.databases();
        for (const db of databases) {
          if (db.name) {
            await indexedDB.deleteDatabase(db.name);
            console.log(`Deleted IndexedDB: ${db.name}`);
          }
        }
      }

      // Clear caches
      if (window.caches) {
        const cacheKeys = await caches.keys();
        for (const key of cacheKeys) {
          await caches.delete(key);
        }
        console.log("Caches cleared.");
      }

      // Clear cookies
      document.cookie.split(";").forEach((cookie) => {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
        console.log(`Cleared cookie: ${name}`);
      });

      console.log("Cookies cleared.");

      // Sign out NextAuth user
      signOut({ redirect: false });

      // Estimate storage usage after clearing
      if (navigator.storage && navigator.storage.estimate) {
        const estimate = await navigator.storage.estimate();
        console.log("Storage usage after clearing:", estimate);
      }
    } catch (error) {
      console.error("Error clearing site data:", error);
    }
  };

  
  const clearAllFirebaseAndStorageData = async () => {
    try {
      // Shut down Firebase services
      await clearFirebaseDatabases();

      // Introduce a delay to ensure shutdown completes
      await delay(2000);

      // Clear all IndexedDB databases
      if (window.indexedDB) {
        const databases = await indexedDB.databases();
        for (const db of databases) {
          if (db.name) {
            await indexedDB.deleteDatabase(db.name);
            console.log(`Deleted database: ${db.name}`);
          }
        }
      }

      console.log("All IndexedDB databases cleared.");
    } catch (error) {
      console.error("Error clearing data:", error);
    }
  };

  useEffect(() => {
    const currentVersion =
      process.env.NEXT_PUBLIC_CURRENT_VERSION || "build-2024-12-05";

    const storedVersion = localStorage.getItem("site-version");

    // If the stored version doesn't match the current build version, clear all data
    if (storedVersion !== currentVersion) {
      (async () => {
        console.log("Site version has changed. Clearing all data...");
        await clearAllFirebaseAndStorageData();
        await clearAllData();

        // Store the new version
        localStorage.setItem("site-version", currentVersion);

        // Optional: Reload the page to ensure new version is loaded
        // window.location.reload();
      })();
    }
  }, []);

  return <>{children}</>; // Render child components
};

export default AppStorageHandler;
