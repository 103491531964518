'use client';

import { useReportWebVitals } from 'next/web-vitals';
import { NextWebVitalsMetric } from 'next/app';

export function WebVitals() {
  useReportWebVitals((metric: NextWebVitalsMetric) => {

    if (metric.label === 'web-vital') {
        // window.gtag('event', metric.name, {
        //   event_category: 'Web Vitals',
        //   value: metric.value,
        //   event_label: metric.id, // Use metric.id for unique tracking
        //   non_interaction: true, // Avoid affecting bounce rate
        // });
    }
    
  });

  // Since this doesn't render anything visually, return null
  return null;
}
