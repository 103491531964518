"use client";

import React, { useEffect, useState } from 'react';
import { DropdownMenu } from '../ui/DropdownMenu';
import Location from '../Location';
import Icon from '../Icon';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNearbyBranches, saveBranch } from '@/store/slices/table_booking_flow/branchesSlice';
import { AppState, AppDispatch } from '@/store/store';
import Loader from '../Loader';
import { findMatchingBranch } from '@/lib/utils';

/**
 * LocationDropdown component that allows users to select a location (branch) from a dropdown menu.
 * The component fetches nearby branches based on user's geolocation and displays a dropdown to select a branch.
 * 
 * @component
 * @returns {JSX.Element} The rendered LocationDropdown component.
 */
const LocationDropdown: React.FC = () => {

    // Get saved branches from Redux state
    const getSavedRecords = useSelector((state: AppState) => state.branches.saved);

    // Redux dispatch hook
    const dispatch: AppDispatch = useDispatch();

    // Get branches, loading and error states from Redux
    const { data: branches, loadingNearbyBranches: loading, errorNearbyBranches: error } = useSelector((state: AppState) => state.branches);

    // Get geolocation from visitorInfo state in Redux
    const { latitude, longitude } = useSelector((state: AppState) => state.visitorInfo);

    // Local state for dropdown open/close status, selected city, selected place and list of locations
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedPlace, setSelectedPlace] = useState("");
    const [Locations, setLocations] = useState<any[]>([]);

    /**
     * Handles the selection of a city and place.
     * Closes the dropdown after a selection.
     * 
     * @param {string} city - The selected city.
     * @param {string} place - The selected place/branch.
     */
    const handleOptionClick = (city: string, place: string) => {
        setIsOpen(false);
    };

    // Fetch nearby branches based on geolocation or fallback coordinates if not available
    useEffect(() => {
        if (longitude && latitude) {
            dispatch(fetchNearbyBranches({ lat: latitude.toString(), long: longitude.toString() }));
        } else {
            dispatch(fetchNearbyBranches({ lat: "0", long: "0" }));
        }
    }, [dispatch, latitude, longitude]);

    // Set the fetched branches to the local state
    useEffect(() => {
        if (branches) {
            setLocations(branches?.data);
        }
    }, [branches]);

    // Set selected city and place from saved records
    useEffect(() => {
        if (getSavedRecords) {
            setCityState();
        }
    }, [getSavedRecords]);

    /**
     * Sets the city and place states based on saved records in local storage.
     */
    const setCityState = () => {
        try {
            if (typeof window === 'undefined') return;
            let getData = localStorage.getItem('bwf');
            if (getData == null || getData == undefined) return;
            let finalData = JSON.parse(getData) as { city_name: string, branch_name: string, details: any };
            setSelectedCity(finalData.city_name);
            setSelectedPlace(finalData.branch_name);
        } catch (error) {
            console.error("Error setting city state:", error);
        }
    };

    // Automatically set a matching nearby branch if none is saved
    useEffect(() => {
        if (getSavedRecords == null && branches && 'near_by' in branches && (branches.near_by).length > 0) {
            const matchingBranch = findMatchingBranch(branches?.data, branches?.near_by[0]);
            if (matchingBranch) {
                dispatch(saveBranch(JSON.stringify(matchingBranch)));
            }
        }
    }, [branches?.near_by]);

    return (
        <>
            <DropdownMenu
                position={"bottom-center"}
                trigger={
                    <>
                        <button
                            aria-expanded={isOpen}
                            aria-controls="location-dropdown"
                            title={`${selectedPlace}, ${selectedCity}`} className="text-wireframe-heading md:text-primary-6 font-inter text-sm leading-[22.4px] md:leading-normal flex items-center gap-1 md:gap-2 md:px-3 md:py-2.5 md:bg-primary-2 rounded-lg ">
                            {loading ?
                                <Loader /> :
                                <>
                                    <Icon name='locationPinBlack20' size={20} className='block md:hidden' />
                                    <Icon name='locationPin' className='hidden md:block' />
                                </>
                            }
                            <span className='overflow-hidden text-ellipsis whitespace-nowrap max-w-32'>
                                {(selectedPlace && selectedCity) ? `${selectedPlace}, ${selectedCity}, ` : 'Select Restaurant'}
                            </span>
                            <Icon name='arrowDownOrange' className=' [&>*]:fill-wireframe-heading md:[&>*]:fill-primary-5' />
                        </button>
                    </>
                }
                isOpen={isOpen}
                onToggle={() => setIsOpen(!isOpen)}
                className='w-[294px]'
                aria-labelledby="location-dropdown"
            >
                {(closeMenu) => (
                    <Location
                        options={Locations}
                        onOptionClick={(city, place) => {
                            handleOptionClick(city, place);
                            closeMenu();
                        }}
                    />
                )}
            </DropdownMenu>
        </>
    );
};

export default React.memo(LocationDropdown);

// export default LocationDropdown;
