import { useEffect, useState } from 'react';

const isBot = (userAgent: string): boolean => {
  const botUserAgents = [
    "Googlebot", "Lighthouse", "PageSpeed", "Speed Insights", "Bingbot", 
    "Baiduspider", "YandexBot", "DuckDuckBot", "SemrushBot", "MJ12bot"
  ];
  return botUserAgents.some(bot => userAgent.includes(bot));
};

export const useGTM = (): boolean => {
  const [shouldLoadGTM, setShouldLoadGTM] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const userAgent = window.navigator.userAgent;
      if (!isBot(userAgent) && process.env.NODE_ENV === "production") {
        const handleInteraction = () => {
          setShouldLoadGTM(true);
          // Remove event listeners once GTM has been loaded
          window.removeEventListener('scroll', handleInteraction);
          window.removeEventListener('click', handleInteraction);
          window.removeEventListener('mousemove', handleInteraction);
        };

        // Load GTM after user interaction (scroll, click, or mouse movement)
        window.addEventListener('scroll', handleInteraction);
        window.addEventListener('click', handleInteraction);
        window.addEventListener('mousemove', handleInteraction);
      }
    }
  }, []);

  return shouldLoadGTM;
};
