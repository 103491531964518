import { SavedBranchInfo, additionalCharge, BranchesState, SlotsData, collectedBookingDetails } from '@/types/next';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

/**
 * Parses saved booking data from localStorage.
 * 
 * @returns {SavedBranchInfo | null} The parsed saved data or null if it doesn't exist or fails to parse.
 */
function parseSavedData(): SavedBranchInfo | null {
  try {
    if (typeof window === 'undefined') return null; // Ensure it's running in a browser environment
    const data = localStorage.getItem('bwf');
    if (data === undefined || data === null) return null;

    return JSON.parse(data);
  } catch (error) {
    console.error('Error parsing saved data:', error);
    return null;
  }
}

/**
 * Initial state for the slots slice, includes booking details, estimates, and data fetching state.
 */
const initialState: BranchesState = {
  data: [],
  loadingNearbyBranches: false,
  loadingSlots: false,
  errorNearbyBranches: null,
  errorSlots: null,
  slotsData: null,
  tableBookingEstimate: {
    subtotal: 0,
    cgst: 0,
    sgst: 0,
    serviceCharge: 0,
    itemTotal: 0,
    total: 0,
  },
  collectedBookingDetails: {
    transaction_type: 'BOOKING',
    voucher_type: 'GC',
    packs: 0,
    reservation_date: '',
    branch_id: 0,
    amount: 0,
    slot_id: 0,
    currency: 'INR',
    booking_details: [],
    loyalty_points: 0,
    voucher_details: [],
  },
  saved: parseSavedData(), // Fetch saved booking data from localStorage
};

/**
 * Async thunk for fetching slots from the API.
 * 
 * @param {Object} params - The parameters for the slot API request.
 * @param {string} params.reservation_date - The reservation date for which to fetch slots.
 * @param {number} params.branch_id - The branch ID for which to fetch available slots.
 * @param {string} [params.dinner_type] - Optional dinner type for slot filtering.
 * 
 * @returns {Promise<SlotsData>} The fetched slots data or an error if the request fails.
 */
export const fetchSlots = createAsyncThunk(
  'slots/fetchSlots',
  async ({ reservation_date, branch_id, dinner_type }: { reservation_date: string, branch_id: number, dinner_type?: string }, { rejectWithValue }) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        reservation_date,
        branch_id,
        dinner_type,
      });

      const response = await fetch("/api/v1/slots", {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow" as RequestRedirect,
      });

      // Handle non-OK response
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData?.error.includes('Blocked by Cloudflare'))
          toast.warn(`${errorData?.error}`)
        return rejectWithValue(errorData);
      }

      // Parse the slots data from the response
      const result = await response.json();
      return result?.results;
    } catch (error: any) {
      // Return the error message if an error occurs
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Redux slice for managing slots and booking estimates.
 * Handles fetching slots, storing booking estimates, and collected booking details.
 */
const slotsSlice = createSlice({
  name: 'slots',
  initialState,
  reducers: {
    /**
     * Updates the booking estimate in the state.
     * 
     * @param {BranchesState} state - The current state of the slice.
     * @param {PayloadAction<additionalCharge>} action - The action containing the new booking estimate.
     */
    bookingEstimate: (state, action: PayloadAction<additionalCharge>) => {
      state.tableBookingEstimate = action.payload;
    },

    /**
     * Updates the collected booking details in the state.
     * 
     * @param {BranchesState} state - The current state of the slice.
     * @param {PayloadAction<collectedBookingDetails>} action - The action containing the new collected booking details.
     */
    collectBookingDetails: (state, action: PayloadAction<collectedBookingDetails>) => {
      state.collectedBookingDetails = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      /**
       * Handles the pending state when fetching slots.
       * 
       * @param {BranchesState} state - The current state of the slice.
       */
      .addCase(fetchSlots.pending, (state) => {
        state.loadingSlots = true;
        state.errorSlots = null;
      })

      /**
       * Handles the fulfilled state when slots are successfully fetched.
       * 
       * @param {BranchesState} state - The current state of the slice.
       * @param {PayloadAction<SlotsData>} action - The action containing the fetched slots data.
       */
      .addCase(fetchSlots.fulfilled, (state, action: PayloadAction<SlotsData>) => {
        state.loadingSlots = false;
        state.slotsData = action.payload;
      })

      /**
       * Handles the rejected state when fetching slots fails.
       * 
       * @param {BranchesState} state - The current state of the slice.
       * @param {PayloadAction<any>} action - The action containing the error message.
       */
      .addCase(fetchSlots.rejected, (state, action: PayloadAction<any>) => {
        state.loadingSlots = false;
        state.errorSlots = action.payload;
      });
  },
});

/** Export actions for booking estimate and collected booking details */
export const { bookingEstimate, collectBookingDetails } = slotsSlice.actions;

/** Export the reducer to be used in the store */
export default slotsSlice.reducer;
