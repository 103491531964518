import { useState } from 'react';

/**
 * Custom hook to manage the visibility state of a modal.
 * 
 * @returns {Object} - The visibility state and functions to control the modal.
 * @property {boolean} isVisible - Indicates whether the modal is visible or not.
 * @property {Function} openModal - Function to open the modal.
 * @property {Function} closeModal - Function to close the modal.
 */
const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  return {
    isVisible,
    openModal,
    closeModal,
  };
};

export default useModal;
