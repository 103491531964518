import { branchDetailsData } from '@/types/next';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

/**
 * Defines the state for branch details.
 * @typedef {Object} branchInfoState
 * @property {branchDetailsData | null} data - The branch details data.
 * @property {string | null} error - Error message, if any.
 * @property {boolean} loading - Loading state for the API request.
 */
interface branchInfoState {
    data: branchDetailsData | null;
    error: string | null;
    loading: boolean;
}

/**
 * The initial state for the branch details slice.
 * @type {branchInfoState}
 */
const initialState: branchInfoState = {
    data: null,
    error: null,
    loading: false,
};

/**
 * Async thunk for fetching branch details by branch_id.
 * Sends a GET request to the `/api/v1/branches/{branch_id}` endpoint.
 * 
 * @async
 * @function fetchBranchDetails
 * @param {Object} params - The parameters for fetching branch details.
 * @param {string} params.branch_id - The branch ID to fetch details for.
 * @returns {Promise<branchDetailsData>} The branch details data.
 * @throws Will throw an error if the request fails.
 */
export const fetchBranchDetails = createAsyncThunk(
    'branch/fetchBranch',
    async ({ branch_id }: { branch_id: string }, { rejectWithValue }) => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const response = await fetch(`/api/v1/branches/${branch_id}`, {
                method: "GET",
                headers: myHeaders,
                redirect: "follow" as RequestRedirect,
                cache:"force-cache",
                next : {
                    revalidate: 60 * 60 * 2,
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                if(errorData?.error.includes('Blocked by Cloudflare'))
                    toast.warn(`${errorData?.error}`)
                return rejectWithValue(errorData);
            }

            const result = await response.json();
            return result?.results as branchDetailsData; // Assuming the result is structured this way
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

/**
 * Slice to handle branch information, including reducers for managing branch details state.
 */
const branchInfoSlice = createSlice({
    name: 'branchInfo',
    initialState,
    reducers: {
        // Define other reducers if needed
    },
    extraReducers: (builder) => {
        builder
            /**
             * Handles the pending state when the fetchBranchDetails thunk is triggered.
             * 
             * @param {branchInfoState} state - The current state of the slice.
             */
            .addCase(fetchBranchDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })

            /**
             * Handles the fulfilled state when branch details are successfully fetched.
             * 
             * @param {branchInfoState} state - The current state of the slice.
             * @param {PayloadAction<branchDetailsData>} action - The action containing the fetched branch details.
             */
            .addCase(fetchBranchDetails.fulfilled, (state, action: PayloadAction<branchDetailsData>) => {
                state.loading = false;
                state.data = action.payload;
            })

            /**
             * Handles the rejected state when fetching branch details fails.
             * 
             * @param {branchInfoState} state - The current state of the slice.
             * @param {PayloadAction<any>} action - The action containing the error message.
             */
            .addCase(fetchBranchDetails.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

// Export the slice reducer
export default branchInfoSlice.reducer;
