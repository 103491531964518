"use client";

import PageSkeleton from "@/components/skeletons/PageSkeleton";
import { getSession, SessionProvider as NextAuthSessionProvider } from "next-auth/react";
import { useEffect, useState } from "react";

export default function SessionProvider({ children }: { children: React.ReactNode }) {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchSession() {
      const session : any = await getSession();
      setSession(session);
      setLoading(false);
    }
    fetchSession();
  }, []);

  if (loading) {
    return <div><PageSkeleton /></div>; // Add a loading fallback
  }

  return <NextAuthSessionProvider session={session}>{children}</NextAuthSessionProvider>;
}
